import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import arrowDownIcon from 'assets/arrow-down.svg';

interface Props {
  open?: boolean;
  controlledOpen?: boolean;
  controlledToggle?: () => void;
  replaceHeaderWithContent?: boolean;
  wrapperClass?: string;
  openWrapperClass?: string;
  containerClass?: string;
  headerClass?: string;
  renderHeader: React.ReactNode;
  renderContent: React.ReactNode;
  onToggle?: (value: boolean) => void;
  animateExpand?: boolean;
  onToggleAnalyticsCb?: () => void;
}

const accordionHiddenClass = 'max-h-0 overflow-hidden';
const accordionActiveClass = 'max-h-[1000px] h-fit';

const Accordion: React.FC<Props> = (props) => {
  const { open = false, controlledOpen = false, replaceHeaderWithContent = false, wrapperClass = '', openWrapperClass ='', containerClass = '', headerClass = '', renderHeader, renderContent, onToggle, onToggleAnalyticsCb, controlledToggle } = props;
  const [isOpen, setIsOpen] = useState(open);

  const handleToggle = () => {
    onToggleAnalyticsCb?.();
    setIsOpen(status => !status);
  };

  useEffect(() => {
    onToggle?.(isOpen);
  }, [isOpen]);

  return (
    <article className={`w-full flex flex-col gap-[2.5px] ${wrapperClass} ${(controlledOpen ? open : isOpen) ? openWrapperClass : ''}`}>
      <section
        className={`flex justify-between items-baseline gap-[10px] rounded-[4px] cursor-pointer select-none ${containerClass}`}
        onClick={controlledOpen ? () => {
          controlledToggle?.();
          onToggle?.(isOpen);
        } : handleToggle}
      >
        <div className={headerClass || ''}>
          {(replaceHeaderWithContent && (controlledOpen ? open : isOpen)) ? renderContent : renderHeader}
        </div>
        <div className='flex min-w-[fit-content] items-center'>
          <Image
            src={arrowDownIcon}
            className={`transform transition-transform ease-in-out duration-300 ${(controlledOpen ? open : isOpen) ? 'rotate-180' : ''}`}
            alt='arrow-icon'
            loading='eager'
          />
        </div>
      </section>
      {
        !replaceHeaderWithContent ? (
          <section
            className={`transition-[max-height] ease-in-out duration-300 ${(controlledOpen ? open : isOpen) ? accordionActiveClass : accordionHiddenClass}`}>
            {renderContent}
          </section>
        ) : null
      }
    </article>
  );
};

Accordion.defaultProps = {
  open: false,
  replaceHeaderWithContent: false,
  wrapperClass: '',
  openWrapperClass: '',
  containerClass: '',
  renderHeader: <></>,
  renderContent: <></>,
};

export default Accordion;
